import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import bSubCategoryService from "./bSubCategoryService";

export const getSubCategories = createAsyncThunk(
    "blogSubCategory/get-subcategories",
    async (thunkAPI) => {
        try {
            return await bSubCategoryService.getBlogSubCategories();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const createSubCategory = createAsyncThunk(
    "blogSubCategory/create-subcategory",
    async (subcategoryData, thunkAPI) => {
        try {
            return await bSubCategoryService.createSubCategory(subcategoryData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updateABlogSubCategory = createAsyncThunk(
    "blogSubCategory/update-subcategory",
    async (subcategory, thunkAPI) => {
        try {
            return await bSubCategoryService.updateBlogSubCategory(subcategory);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteABlogSubCategory = createAsyncThunk(
    "blogSubCategory/delete-subcategory",
    async (id, thunkAPI) => {
        try {
            return await bSubCategoryService.deleteBlogSubCategory(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getABlogSubCategory = createAsyncThunk(
    "blogSubCategory/get-blog-subcategory",
    async (id, thunkAPI) => {
        try {
            return await bSubCategoryService.getBlogSubCategory(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const resetState = createAction("RevertAll");

const initialState = {
    bSubCategories: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const bSubCategorySlice = createSlice({
    name: "bSubCategories",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.bSubCategories = action.payload;
            })
            .addCase(getSubCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(createSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.createdSubCategory = action.payload;
            })
            .addCase(createSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateABlogSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateABlogSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updatedSubCategory = action.payload;
            })
            .addCase(updateABlogSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteABlogSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteABlogSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.deletedSubCategory = action.payload;
            })
            .addCase(deleteABlogSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getABlogSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getABlogSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.subCategoryName = action.payload.title;
            })
            .addCase(getABlogSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default bSubCategorySlice.reducer;
