import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import pSubCategoryService from "./pSubCategoryService";

export const getSubCategories = createAsyncThunk(
    "productSubCategory/get-subcategories",
    async (thunkAPI) => {
        try {
            return await pSubCategoryService.getProductSubCategories();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const createSubCategory = createAsyncThunk(
    "productSubCategory/create-subcategory",
    async (subcategoryData, thunkAPI) => {
        try {
            return await pSubCategoryService.createSubCategory(subcategoryData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updateAProductSubCategory = createAsyncThunk(
    "productSubCategory/update-subcategory",
    async (subcategory, thunkAPI) => {
        try {
            return await pSubCategoryService.updateProductSubCategory(subcategory);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteAProductSubCategory = createAsyncThunk(
    "productSubCategory/delete-subcategory",
    async (id, thunkAPI) => {
        try {
            return await pSubCategoryService.deleteProductSubCategory(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getAProductSubCategory = createAsyncThunk(
    "productSubCategory/get-product-subcategory",
    async (id, thunkAPI) => {
        try {
            return await pSubCategoryService.getProductSubCategory(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const resetState = createAction("RevertAll");

const initialState = {
    pSubCategories: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const pSubCategorySlice = createSlice({
    name: "pSubCategories",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.pSubCategories = action.payload;
            })
            .addCase(getSubCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(createSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.createdSubCategory = action.payload;
            })
            .addCase(createSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateAProductSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateAProductSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updatedSubCategory = action.payload;
            })
            .addCase(updateAProductSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteAProductSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteAProductSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.deletedSubCategory = action.payload;
            })
            .addCase(deleteAProductSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getAProductSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAProductSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.subCategoryName = action.payload.title;
            })
            .addCase(getAProductSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default pSubCategorySlice.reducer;
