import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense, lazy, useState, useEffect } from "react";
import MainLayout from "./components/MainLayout";
import { OpenRoutes } from "./routing/OpenRoutes";
import { PrivateRoutes } from "./routing/PrivateRoutes";
//import loader from "../asset/wib.png"
// Lazy load components
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const Enquiries = lazy(() => import("./pages/Enquiries"));
const Bloglist = lazy(() => import("./pages/Bloglist"));
const Blogcatlist = lazy(() => import("./pages/Blogcatlist"));
const Orders = lazy(() => import("./pages/Orders"));
const Customers = lazy(() => import("./pages/Customers"));
const Colorlist = lazy(() => import("./pages/Colotlist"));
const Categorylist = lazy(() => import("./pages/Categorylist"));
const Taglist = lazy(() => import("./pages/taglist"));
const Sizelist = lazy(() => import("./pages/sizelist"));
const Brandlist = lazy(() => import("./pages/Brandlist"));
const Productlist = lazy(() => import("./pages/Productlist"));
const Addblog = lazy(() => import("./pages/Addblog"));
const Addblogcat = lazy(() => import("./pages/Addblogcat"));
const BlogSubcategorylist = lazy(() => import("./pages/bSubcategoryList"));
const Addcolor = lazy(() => import("./pages/Addcolor"));
const Addcat = lazy(() => import("./pages/Addcat"));
const AddSubCat = lazy(() => import("./pages/AddSubCat"));
const AddBlogSubCat = lazy(() => import("./pages/AddblogSubcat"));
const AddTag = lazy(() => import("./pages/AddTag"));
const AddSize = lazy(() => import("./pages/AddSize"));
const Addbrand = lazy(() => import("./pages/Addbrand"));
const Addproduct = lazy(() => import("./pages/Addproduct"));
const Couponlist = lazy(() => import("./pages/Couponlist"));
const AddCoupon = lazy(() => import("./pages/AddCoupon"));
const ViewEnq = lazy(() => import("./pages/ViewEnq"));
const ViewOrder = lazy(() => import("./pages/ViewOrder"));
const Subcategorylist = lazy(() => import("./pages/subCategorylist"));



function App() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 30000); // Display the loader for 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          showLoader && (
            <div className="fixed inset-0 flex justify-center items-center bg-white z-50">

              <div className="relative flex justify-center items-center">
                <div className="absolute animate-spin rounded-full h-36 w-36 border-t-4 border-b-4 border-red-500"></div>
                <img
                  src="/asset/wib.png"
                  className="rounded-full h-28 w-28"
                  alt="Wub-fashion...."
                />
              </div>
            </div>
          )
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <OpenRoutes>
                <Login />
              </OpenRoutes>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoutes>
                <MainLayout />
              </PrivateRoutes>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="enquiries" element={<Enquiries />} />
            <Route path="enquiries/:id" element={<ViewEnq />} />
            <Route path="blog-list" element={<Bloglist />} />
            <Route path="blog" element={<Addblog />} />
            <Route path="blog/:id" element={<Addblog />} />
            <Route path="coupon-list" element={<Couponlist />} />
            <Route path="coupon" element={<AddCoupon />} />
            <Route path="coupon/:id" element={<AddCoupon />} />
            <Route path="blog-category-list" element={<Blogcatlist />} />
            <Route path="blog-category" element={<Addblogcat />} />
            <Route path="blog-category/:id" element={<Addblogcat />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order/:id" element={<ViewOrder />} />
            <Route path="customers" element={<Customers />} />
            <Route path="list-color" element={<Colorlist />} />
            <Route path="color" element={<Addcolor />} />
            <Route path="color/:id" element={<Addcolor />} />
            <Route path="list-category" element={<Categorylist />} />
            <Route path="list-subcategory" element={<Subcategorylist />} />
            <Route path="blog-subcategory-list" element={<BlogSubcategorylist />} />
            <Route path="list-tag" element={<Taglist />} />
            <Route path="list-size" element={<Sizelist />} />
            <Route path="category" element={<Addcat />} />
            <Route path="category/:id" element={<Addcat />} />
            <Route path="subcategory" element={<AddSubCat />} />
            <Route path="blogSubcategory" element={<AddBlogSubCat />} />
            <Route path="subcategory/:id" element={<AddSubCat />} />
            <Route path="blogSubcategory/:id" element={<AddBlogSubCat />} />
            <Route path="tags" element={<AddTag />} />
            <Route path="tag/:id" element={<AddTag />} />
            <Route path="sizes" element={<AddSize />} />
            <Route path="size/:id" element={<AddSize />} />
            <Route path="list-brand" element={<Brandlist />} />
            <Route path="brand" element={<Addbrand />} />
            <Route path="brand/:id" element={<Addbrand />} />
            <Route path="list-product" element={<Productlist />} />
            <Route path="product" element={<Addproduct />} />
            <Route path="product/:id" element={<Addproduct />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
