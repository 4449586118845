import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getProductSubCategories = async () => {
    const response = await axios.get(`${base_url}subcategory/`);
    return response.data;
};

const createSubCategory = async (subcategory) => {
    const response = await axios.post(`${base_url}subcategory/`, subcategory, config);
    return response.data;
};

const getProductSubCategory = async (id) => {
    const response = await axios.get(`${base_url}subcategory/${id}`, config);
    return response.data;
};

const deleteProductSubCategory = async (id) => {
    const response = await axios.delete(`${base_url}subcategory/${id}`, config);
    return response.data;
};

const updateProductSubCategory = async (subcategory) => {
    const response = await axios.put(
        `${base_url}subcategory/${subcategory.id}`,
        { title: subcategory.pSubCatData.title },
        config
    );
    return response.data;
};

const pSubCategoryService = {
    getProductSubCategories,
    createSubCategory,
    getProductSubCategory,
    deleteProductSubCategory,
    updateProductSubCategory,
};

export default pSubCategoryService;
